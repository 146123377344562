
//公共库
import { Component, Vue } from "vue-property-decorator";
//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getUserScene, exportUserScene } from "@/api/request/bigdata";

//组件
@Component({
  name: "StayStatistics",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = false;

  //请求参数
  private listQuery: any = {
    //页面数据
    row: 20,
    page: 1,

    //临时数据
    time: [],

    //请求数据
    sex: -1, //性别
    is_blogger: -1, //用户类型
    start_date: "", //开始时间
    end_date: "", //结束时间

    //是否导出Excel
    excel: false,
  };

  //时间参数
  get pickerOptions() {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //性别类型
  private sexTypeList: any[] = [
    { value: -1, label: "全部性别" },
    { value: 0, label: "保密" },
    { value: 1, label: "男" },
    { value: 1, label: "女" },
  ];

  //用户类型
  private userTypeList: any[] = [
    { value: -1, label: "全部账号" },
    { value: 0, label: "用户" },
    { value: 1, label: "博主" },
  ];

  //创建时调用
  created() {
    //获取列表
    this.getList();
  }

  ////获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_date = this.listQuery.time[0];
      this.listQuery.end_date = this.listQuery.time[1];
    } else {
      this.listQuery.start_date = "";
      this.listQuery.end_date = "";
    }
    this.listQuery.excel = false;
    if (this.listQuery.user_id) this.listQuery.user_id = Number(this.listQuery.user_id);

    //获取数据
    const { data } = await getUserScene(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //导出表格
  private async handleExcel() {
    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_date = this.listQuery.time[0];
      this.listQuery.end_date = this.listQuery.time[1];
    } else {
      this.listQuery.start_date = "";
      this.listQuery.end_date = "";
    }
    this.listQuery.excel = true;
    if (this.listQuery.user_id) this.listQuery.user_id = Number(this.listQuery.user_id);

    //获取数据
    const { data } = await exportUserScene(this.listQuery);

    //导出表格
    this.listQuery.time && this.listQuery.time.length == 2 ? saveAs(data, `${this.listQuery.time[0]}--${this.listQuery.time[1]}停留统计`) : saveAs(data, "停留统计总览");
  }

  //处理重置
  private handleReset(): void {
    //数据重置
    this.listQuery = {
      //页面数据
      row: 20,
      page: 1,

      //临时数据
      time: [],

      //请求数据
      sex: -1, //性别
      is_blogger: -1, //用户类型
      start_date: "", //开始时间
      end_date: "", //结束时间

      //是否导出Excel
      excel: false,
    };

    //获取数据
    this.getList();
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;
    this.listQuery.excel = false;

    //获取数据
    this.getList();
  }

  //性别筛选
  private getSexStr(row: any): string {
    //判断返回
    if (row.sex === 0) return "保密";
    if (row.sex === 1) return "男";
    if (row.sex === 2) return "女";

    //返回空
    return "";
  }

  //获取时长文本
  private secondsToHMS(seconds: any): string {
    //数据赋值
    var remainingSeconds = seconds % 60;
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var formattedTime = hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0") + ":" + remainingSeconds.toString().padStart(2, "0");

    //返回结果
    return formattedTime;
  }
}
